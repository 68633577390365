import React from "react"

interface LeadingTextProps {
  children: React.ReactNode
  className?: string
}

const LeadingTextFn = React.forwardRef(
  (props: LeadingTextProps, ref: React.ForwardedRef<HTMLParagraphElement>) => {
    const { children, className, ...rest } = props
    return (
      <p
        className={`text-md text-medium-contrast ${className || ""}`}
        ref={ref}
        {...rest}
      >
        {children}
      </p>
    )
  },
)
LeadingTextFn.displayName = "LeadingText"

export const LeadingText = React.memo(LeadingTextFn) as typeof LeadingTextFn
