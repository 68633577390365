"use client"

import React, { ComponentProps, useCallback, useEffect, useState } from "react"
import { Button, useToast } from "@daybridge/components"

type CopyButtonProps = ComponentProps<typeof Button> & {
  text: string
  toastText?: string
  copiedLabel?: React.ReactNode
}

const CopyButtonFn = React.forwardRef(
  (props: CopyButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const { children, onClick, toastText, text, icon, copiedLabel, ...rest } =
      props

    const { isCopied, handleCopy } = useCopy(text, toastText)

    return (
      <Button
        ref={ref}
        {...rest}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          handleCopy()
          onClick?.(e)
        }}
        icon={isCopied ? "Tick" : icon}
      >
        {isCopied ? copiedLabel : children}
      </Button>
    )
  },
)
CopyButtonFn.displayName = "CopyButton"

export const CopyButton = React.memo(CopyButtonFn) as typeof CopyButtonFn

export const useCopy = (text?: string, toastText?: string) => {
  const [isCopied, setIsCopied] = useState(false)
  const toast = useToast()

  const handleCopy = useCallback(() => {
    if (!text) return
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setIsCopied(true)
        typeof toast.success === "function" &&
          toast.success({ title: toastText || "Copied to clipboard" })
      })
      .catch((e) => {
        console.log(e)
        setIsCopied(false)
      })
  }, [text, toast, toastText])

  useEffect(() => {
    if (!isCopied) return
    const timer = setTimeout(() => {
      setIsCopied(false)
    }, 3000)

    return () => {
      clearTimeout(timer)
    }
  }, [isCopied])

  return { isCopied, handleCopy }
}
