import React from "react"

interface H1Props {
  children: React.ReactNode
  className?: string
}

const H1Fn = React.forwardRef(
  (props: H1Props, ref: React.ForwardedRef<HTMLHeadingElement>) => {
    const { children, className, ...rest } = props
    return (
      <h1
        className={`text-high-contrast text-xl font-semibold ${
          className || ""
        }`}
        ref={ref}
        {...rest}
      >
        {children}
      </h1>
    )
  },
)
H1Fn.displayName = "H1"

export const H1 = React.memo(H1Fn) as typeof H1Fn
