import React from "react"
import { Icon } from "@daybridge/components"

interface ChevronListItemProps {
  children: React.ReactNode
  className?: string
}

const ChevronListItemFn = React.forwardRef(
  (props: ChevronListItemProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { children, className, ...rest } = props
    return (
      <div
        ref={ref}
        className={`flex flex-row items-center space-x-2 ${className || ""}`}
        {...rest}
      >
        <Icon name="ChevronRight" className="w-2 text-low-contrast inline" />
        {children}
      </div>
    )
  },
)
ChevronListItemFn.displayName = "ChevronListItem"

export const ChevronListItem = React.memo(
  ChevronListItemFn,
) as typeof ChevronListItemFn
