import { cn } from "@daybridge/cn"
import React from "react"

const H3Fn = React.forwardRef(
  (
    props: React.HTMLAttributes<HTMLHeadingElement>,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const { children, className, ...rest } = props
    return (
      <h3
        ref={ref}
        className={cn(
          "text-sm text-medium-contrast uppercase font-semibold",
          className,
        )}
        {...rest}
      >
        {children}
      </h3>
    )
  },
)
H3Fn.displayName = "H3"

export const H3 = React.memo(H3Fn) as typeof H3Fn
