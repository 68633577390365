"use client"

import React from "react"
import Image from "next/image"
import { Button, Icon } from "@daybridge/components"
import { cn } from "@daybridge/cn"
import { H1 } from "../heading/H1"
import { ChevronListItem } from "../list/ChevronListItem"
import { LeadingText } from "../text/LeadingText"
import {
  Collapse,
  CollapseContent,
  CollapseTrigger,
} from "../collapse/Collapse"
import { H3 } from "../heading/H3"
import { CopyButton } from "../copy-button/CopyButton"

type ErrorWithDigest = Error & { digest?: string }

interface ErrorProps {
  // Localised strings
  title: string
  message: string
  tryAgainText: string
  reloadAppText: string
  copyErrorDetailsText: string
  showDetailsText: string
  copiedText: string

  // `error` is the error that was thrown
  error?: ErrorWithDigest

  // `reset` is a function that will reset the error boundary
  reset?: () => void

  // Hide resolution links if they are not relevant
  hideResolutionOptions?: boolean
}

const ErrorFn = React.forwardRef(
  (props: ErrorProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const {
      title,
      message,
      tryAgainText,
      reloadAppText,
      copyErrorDetailsText,
      showDetailsText,
      copiedText,

      error,
      reset,
      hideResolutionOptions,
      ...rest
    } = props

    const {
      name: errName,
      message: errMessage,
      cause: errCause,
      stack: errStack,
      ...errRest
    } = error || {}

    const errorText = JSON.stringify({
      name: errName,
      message: errMessage,
      cause: errCause,
      stack: errStack,
      ...errRest,
    })

    return (
      <div
        className="
          w-full h-full
          flex flex-col items-center justify-center
        "
        ref={ref}
        {...rest}
      >
        <div
          className="
            w-full max-w-xl
            p-8
            flex flex-col items-start justify-center
            space-y-2
          "
        >
          <div className="relative mb-4 flex flex-col items-center flex-shrink-0">
            <Image
              width={157}
              height={106}
              alt="A wireframe illustration of the Daybridge app"
              src="/images/illustrations/app-skeleton-light.svg"
              className="dark:hidden"
            />
            <Image
              width={157}
              height={106}
              alt="A wireframe illustration of the Daybridge app"
              src="/images/illustrations/app-skeleton-dark.svg"
              className="hidden dark:block"
            />

            <Icon
              name="Warning"
              className="w-6 absolute bottom-px text-state-warning-primary"
            />
          </div>

          <div className="w-full">
            <H1 className="mb-1">{title}</H1>
            <LeadingText className="mb-4">{message}</LeadingText>
            {!hideResolutionOptions && (
              <div className="flex flex-col items-start space-y-0.5 text-base">
                {reset && (
                  <ChevronListItem>
                    <Button
                      variant="link"
                      theme="brand-blue"
                      onClick={() => reset()}
                    >
                      {tryAgainText}
                    </Button>
                  </ChevronListItem>
                )}
                <ChevronListItem>
                  {/* when this is clicked go to the root path / */}
                  <Button
                    variant="link"
                    theme="brand-blue"
                    onClick={() => (window.location.href = "/")}
                  >
                    {reloadAppText}
                  </Button>
                </ChevronListItem>
                <ChevronListItem>
                  <CopyButton
                    variant="link"
                    theme="brand-blue"
                    text={errorText}
                    copiedLabel={copiedText}
                  >
                    {copyErrorDetailsText}
                  </CopyButton>
                </ChevronListItem>
              </div>
            )}
            <Collapse className="w-full mt-6">
              <CollapseTrigger asChild>
                <Button
                  variant="translucent"
                  className="text-base -ml-4"
                  chevron
                >
                  {showDetailsText}
                </Button>
              </CollapseTrigger>
              <CollapseContent className="py-6 space-y-4">
                {error?.name && (
                  <div>
                    <H3>Name</H3>
                    <pre
                      className={cn(
                        "w-full",
                        "mt-2 p-4",
                        "bg-tint",
                        "text-sm",
                        "overflow-auto",
                        "select-auto",
                        "rounded-lg",
                      )}
                    >
                      {props.error?.name}
                    </pre>
                  </div>
                )}
                {error?.digest && (
                  <div>
                    <H3>Error Digest</H3>
                    <pre
                      className={cn(
                        "w-full",
                        "mt-2 p-4",
                        "bg-tint",
                        "text-sm",
                        "overflow-auto",
                        "select-auto",
                        "rounded-lg",
                      )}
                    >
                      {props.error?.digest}
                    </pre>
                  </div>
                )}
                {error?.message && (
                  <div>
                    <H3>Error Message</H3>
                    <pre
                      className={cn(
                        "w-full",
                        "mt-2 p-4",
                        "bg-tint",
                        "text-sm",
                        "overflow-auto",
                        "select-auto",
                        "rounded-lg",
                      )}
                    >
                      {props.error?.message}
                    </pre>
                  </div>
                )}
                {error?.stack && (
                  <div>
                    <H3>Call Stack</H3>
                    <pre
                      className={cn(
                        "w-full",
                        "mt-2 p-4",
                        "bg-tint",
                        "text-sm",
                        "overflow-auto",
                        "select-auto",
                        "rounded-lg",
                      )}
                    >
                      {props.error?.stack}
                    </pre>
                  </div>
                )}
              </CollapseContent>
            </Collapse>
          </div>
        </div>
      </div>
    )
  },
)
ErrorFn.displayName = "Error"

export const Error = React.memo(ErrorFn) as typeof ErrorFn
